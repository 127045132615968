import { ApolloLogo } from '@apollo/orbit';
import { utmGrabber } from '@apollo/utm-grabber';
import React, { useEffect } from 'react';

import { OnboardingLayoutBackground } from '../onboardingLayoutBackground/OnboardingLayoutBackground';

export const OnboardingLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    // https://github.com/apollographql/dxe/tree/main/packages/utm-grabber
    utmGrabber();
  }, []);

  return (
    <OnboardingLayoutBackground>
      <div
        key="onboarding-content"
        className="relative z-50 mx-auto size-full max-w-md"
      >
        <div className="-mx-4 px-8">
          <div className="mb-12 flex flex-none items-center justify-center pt-14">
            <ApolloLogo
              logoType="wordmark"
              colorScheme="primary"
              className="h-12"
            />
          </div>
          <div className="flex-1 pb-8">{children}</div>
        </div>
      </div>
    </OnboardingLayoutBackground>
  );
};
